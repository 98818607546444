// Header Base

.app-header {
  position: relative;
  z-index: 10;

  display: flex;
  align-content: center;
  align-items: center;

  height: $app-header-height;

  transition: all .2s;

  &.header-shadow {
    box-shadow: $box-shadow-default;
  }

  .mobile-contact {
    display: none;
  }

  .app-header__content {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    flex: 1;
    height: $app-header-height;
    padding: 0 $layout-spacer-x;
    
    .hide-on-mobile {
      display: flex;
      @media(max-width:990px) {
        display: none;
      }
    }

    .app-header-left {
      display: flex;
      align-items: center;
    }

    .app-header-right {
      display: flex;
      align-items: center;
      margin-left: auto;
      
      @media(max-width:991px) {
        background: $white;
        @include border-radius(50px);
        box-shadow: $box-shadow-default;
        padding: 5px 10px ;
      }
    }
  }

  .header-user-info {
    & > .widget-heading,
    & > .widget-subheading {
      white-space: nowrap;
    }

    & > .widget-subheading {
      font-size: $font-size-xs;
    }
  }
}

.app-header__logo {
  display: flex;
  align-items: center;

  width: $app-sidebar-width;
  height: $app-header-height;
  padding: 0 $layout-spacer-x;

  transition: width .2s;

  .logo-src {
    width: $logo-width;
    height: $logo-height;
    // background: url('~assets/utils/images/logo-inverse.png');

    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
  }
}

.app-header__menu,
.app-header__mobile-menu {
  display: none;
  align-items: center;

  height: $app-header-height;
  padding: 0 $layout-spacer-x;
}

// Header Modifiers

@import "modifiers/fixed-header";
@import "modifiers/header-dots";
@import "modifiers/header-megamenu";
@import "modifiers/header-buttons";
//@import "modifiers/header-horizontal";

// Header Themes

@import "themes/header-light";
@import "themes/header-dark";
//@import "themes/header-inverted";
