.error-404-page {
  display: flex;
  width: 100%;
  height: 100%;
  
  svg {
    max-width: 40vw;
    max-height: 40vh;
  }

  h1 {
    font-weight: 700;
  }
}
