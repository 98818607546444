.react-datepicker-wrapper {
  width: 260px;
}

.data-ranger-wrap {
  margin-right: 8px;
}

.input-datepicker-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 2px;
}

.react-datepicker-popper {
  margin-right: 10px;
  width: 280px;
}

.active .sidebar-menu-item {
  color: #fff;
  background-color: #545cd8;
  border-color: #545cd8;

  svg {
    fill: #fff;
  }
}

.widget-chart {
  .ball-beat {
    div {
      height: 10px;
      width: 10px;
    }
  }
}

.dateRangeSearch {
  display: flex;
  justify-content: space-between;
}

.numberInput {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #bcbff0;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

button {
  .ball-beat {
    div {
      height: 10px;
      width: 10px;
    }
  }
}

.btn-primary,
.btn-secondary {
  svg {
    fill: #fff;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorElementsList {
  div {
    &:not(:last-of-type) {
      border-bottom: 1px solid #e9ecef;
      padding-bottom: 6px;
      margin-bottom: 6px;
    }
  }
}

@media (max-width: 500px) {
  .input-datepicker-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .data-ranger-wrap {
    margin-bottom: 5px;
  }
}
.SVGLogo {
  height: 22px;
  width: 22px;
}

.cursor-pointer {
  cursor: pointer;
}

.tool-tip {
  flex-direction: column;
  align-items: center;
  display: flex;
  padding: 10px;
  z-index: 101;
  right: 0px;
  background: #ffffff;
  border-radius: 6px;
  width: 200px;
  box-shadow: 4px 4px 23px rgba(0, 0, 0, 0.07);
  p {
    margin: 0px;
    font-size: 16px;
  }
  a {
    margin: 0px;
    padding: 0px;
    font-size: 15px;
  }
}
.m0 {
  margin: 0px !important;
}
.mtb-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.tip__link {
  cursor: pointer;
  color: #545cd8;
  &:hover {
    text-decoration: underline;
  }
}
.flex-wrap {
  flex-wrap: wrap;
}
