.select-day {
    position: relative;
 }

 .select-date:hover {
       .select-day[selectDayValue]::after {
       content: attr(selectDayValue);
       position: absolute; 
       left: -55px;
       top: -40px;
       z-index: 1;
       background:#2a87d0; 
       font-size: 11px;
       border: 1px solid #333;
       border-radius: 5px;
       padding: 2px 10px;
       text-align: start center;
       font-weight: normal;
       color :inherit;
    }
 }